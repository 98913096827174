import React, { Component } from 'react';
import ContactForm from './contact-form';

export default class Contact extends Component {

  render() {
    return (
      <section className="page" id="contact">
        <div className="content" id="contact-content">
          <div className="title" id="contact-caption">contact</div>
          <div id="form-wrapper">
            <div id="address"><div id="address-name">TJ Gascho</div><a className="page-link" href="mailto:tjam2010@uw.edu">tjam2010@uw.edu</a></div>
            <ContactForm/>
          </div>
        </div>
        <div id="footer">
          <div id="footer-content">
            <span>© 2020 TJ Gascho</span>
            <a id="linkedin" href="https://www.linkedin.com/in/tjgascho" rel="noopener noreferrer" target="_blank">
              <svg viewBox="0 50 512 512" height="40">
                <path fill="#ffffff" d="M150.65,100.682c0,27.992-22.508,50.683-50.273,50.683c-27.765,0-50.273-22.691-50.273-50.683
                C50.104,72.691,72.612,50,100.377,50C128.143,50,150.65,72.691,150.65,100.682z M143.294,187.333H58.277V462h85.017V187.333z
                M279.195,187.333h-81.541V462h81.541c0,0,0-101.877,0-144.181c0-38.624,17.779-61.615,51.807-61.615
                c31.268,0,46.289,22.071,46.289,61.615c0,39.545,0,144.181,0,144.181h84.605c0,0,0-100.344,0-173.915
                s-41.689-109.131-99.934-109.131s-82.768,45.369-82.768,45.369V187.333z"/>
              </svg>
            </a>
          </div>
        </div>
      </section>
    );
  }
}